import { EaseChatSDK, EaseChatClient } from '@/IM/initwebsdk'
import { setMessageKey, createMessage } from '@/utils/handleSomeData'
import { sendMsg } from '@/api/custom'
import _ from 'lodash'
// import { ref, toRaw } from 'vue';
import { messageType } from '@/constant'
import { usePlayRing } from '@/hooks'
import Groups from './goups'
const { ALL_MESSAGE_TYPE } = messageType

const Message = {
    state: {
        messageList: {},
        newMessageSessionKey: ''
    },
    mutations: {
        UPDATE_NEW_MESSAGE_SESSION_KEY: (state, key) => {
            state.newMessageSessionKey = key
        },
        UPDATE_MESSAGE_LIST: (state, msgBody) => {
            const toUpdateMsgList = _.assign({}, state.messageList)
            const listKey = setMessageKey(msgBody)
            if (!toUpdateMsgList[listKey]) {
                toUpdateMsgList[listKey] = []
                _.unionBy(toUpdateMsgList[listKey].push(msgBody), (m) => m.id)
            } else {
                _.unionBy(toUpdateMsgList[listKey].push(msgBody), (m) => m.id)
            }
            state.messageList = toUpdateMsgList
        },
        UPDATE_HISTORY_MESSAGE: (state, payload) => {
            const { listKey, historyMessage } = payload
            const toUpdateMsgList = _.assign({}, state.messageList)
            if (!toUpdateMsgList[listKey]) {
                toUpdateMsgList[listKey] = []
                _.unionBy(
                    toUpdateMsgList[listKey].push(...historyMessage),
                    (m) => m.id
                )
            } else {
                // historyMessage
                console.log(
                    '%c Line:36 🥪 获取到的历史消息 historyMessage',
                    'color:#b03734',
                    historyMessage
                )
                if (toUpdateMsgList[listKey][0].id !== historyMessage[0].id) {
                    _.unionBy(
                        toUpdateMsgList[listKey].unshift(...historyMessage),
                        (m) => m.id
                    )
                }
            }
            state.messageList = toUpdateMsgList
        },
        //清除某条会话消息
        CLEAR_SOMEONE_MESSAGE: (state, payload) => {
            state.messageList[payload] = []
        },
        //撤回删除消息
        CHANGE_MESSAGE_BODAY: (state, payload) => {
            const { type, key, mid } = payload
            if (type === 'recall') {
                if (state.messageList[key]) {
                    const res = _.find(
                        state.messageList[key],
                        (o) => o.id === mid
                    )
                    res.isRecall = true
                }
            }
            if (type === 'deleteMsg') {
                if (state.messageList[key]) {
                    const sourceData = state.messageList[key]
                    const index = _.findIndex(
                        state.messageList[key],
                        (o) => o.id === mid
                    )
                    sourceData.splice(index, 1)
                    state.messageList[key] = _.assign([], sourceData)
                }
            }
        }
    },
    actions: {
        //添加新消息
        createNewMessage: ({ dispatch, commit }, params) => {
            console.log(
                '%c Line:79 🌰新消息>>>>>>> params',
                'color:#33a5ff',
                params
            )
            const { isOpenPlayRing, playRing } = usePlayRing()
            const key = setMessageKey(params)
            commit('UPDATE_MESSAGE_LIST', params)
            //目前根据全局配置进行新消息声音提示，后续计划根据会话级别可进行设置是否声音提示，比如设定免打扰。
            if (isOpenPlayRing.value) {
                const loginUserId = EaseChatClient.user
                if (loginUserId != params.from) {
                    playRing()
                    if (
                        params.type == 'txt' &&
                        params.msg &&
                        params.msg.indexOf('@') > -1
                    ) {
                        if (
                            window.Notification &&
                            Notification.permission !== 'denied'
                        ) {
                            Notification.requestPermission(function (status) {
                                var n = new Notification('您有新消息', {
                                    body: '新消息：' + params.msg
                                })
                            })
                        }
                    }
                }
            }
            dispatch('gatherConversation', key)
        },
        //获取历史消息
        getHistoryMessage: async ({ dispatch, commit }, params) => {
            const { id, chatType, cursor } = params
            console.log(
                '%c Line:84 🌽获取历史消息 params',
                'color:#f5ce50',
                params
            )
            return new Promise((resolve, reject) => {
                const options = {
                    targetId: id,
                    pageSize: 10,
                    cursor: cursor,
                    chatType: chatType,
                    searchDirection: 'up'
                }
                EaseChatClient.getHistoryMessages(options)
                    .then((res) => {
                        const { cursor, messages } = res
                        messages.length > 0 &&
                            messages.forEach((item) => {
                                item.read = true
                            })
                        resolve({ messages, cursor })
                        commit('UPDATE_HISTORY_MESSAGE', {
                            listKey: id,
                            historyMessage: _.reverse(messages)
                        })
                        //提示会话列表更新
                        dispatch('gatherConversation', id)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        //发送展示类型消息
        sendShowTypeMessage: async ({ dispatch, commit, state }, params) => {
            return new Promise((resolve, reject) => {
                console.log('%c Line:126 🍖 params', 'color:#ed9ec7', params)
                //主要作用为创建消息Options中附件会有上传失败的回调函数。
                //传入errorCallback，让附件类型消息在上传失败时调用reject抛出error

                // params
                let params2 = {}
                let msgData = {}
                if (params.msgType == 'txt') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.msg,
                        senType: 'text'
                    }
                } else if (params.msgType == 'img') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.imgUrl,
                        senType: 'image'
                    }
                } else if (params.msgType == 'audio') {
                    let content = {
                        url: params.msgOptions.file.url,
                        length: params.msgOptions.length
                    }
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: JSON.stringify(content),
                        senType: 'audio'
                    }
                } else if (params.msgType == 'video') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.url,
                        senType: 'video'
                    }
                    params.msgOptions.customExts = params.msgOptions.url
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                } else if (params.msgType == 'wx') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.msg,
                        senType: 'wx'
                    }
                    params.msgOptions.customExts = params.msgOptions.msg
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                } else if (params.msgType == 'goods') {
                    msgData = {
                        fromUser: EaseChatClient.user,
                        content: params.msgOptions.goods,
                        senType: 'goods'
                    }
                    params.msgOptions.customExts = params.msgOptions.goods
                    params = {
                        msgType: 'custom',
                        msgOptions: params.msgOptions
                    }
                }
                if (params.msgOptions.chatType == 'groupChat') {
                    // 群聊
                    msgData.toGroup = params.msgOptions.id
                    // state
                    console.log("%c Line:211 🥝 Groups", "color:#e41a6a", Groups.state.groupsInfos);
                    if (Groups.state.groupsInfos[params.msgOptions.id]) {
                        let persons = Groups.state.groupsInfos[params.msgOptions.id]
                    }

                } else if (params.msgOptions.chatType == 'singleChat') {
                    // 单聊
                    msgData.toUser = params.msgOptions.id
                }
                commit('UPDATE_NEW_MESSAGE_SESSION_KEY', params.msgOptions.id)

                const errorCallback = (error) => {
                    reject(error)
                }

                const options = createMessage.createOptions(
                    params,
                    errorCallback
                )
                const msg = EaseChatSDK.message.create(options)
                sendMsg(msgData)
                    .then((res) => {
                        console.log('%c Line:138 🍋 res', 'color:#93c0a4', res)

                        if (res.code == 200) {
                            localStorage.setItem('checkAccountStatus', JSON.stringify(res.data.checkAccountStatus))
                            localStorage.setItem('checkAccounts', JSON.stringify(res.data.checkAccounts))
                            const msgIdMap = res.data.msgIdMap
                            console.log('>>>>发送成功', msgIdMap)
                            msg.id = msgIdMap[params.msgOptions.id]
                            msg.from = EaseChatClient.user
                            const msgBody = createMessage.createMsgBody(msg)
                            msgBody.type =
                                msgData.senType == 'text' ? 'txt' : 'custom'
                            msgBody.customEvent =
                                msgData.senType == 'text' ? '' : msgData.senType
                            if (msgData.senType != 'text') {
                                msgBody.customExts = {
                                    content: msgData.content
                                }
                            }
                            if (msgData.toUser) {
                                commit('UPDATE_MESSAGE_LIST', msgBody)
                            }
                            // 提示会话列表更新
                            dispatch('gatherConversation', msgBody.to)
                            resolve('OK')
                        }
                    })
                    .catch((err) => {
                        let obj = {
                            type: 'error',
                            message: err.msg
                        }
                        reject(obj)
                    })
                // EaseChatClient.send(msg)
                //     .then((res) => {
                //         const { serverMsgId } = res
                //         console.log('>>>>发送成功demo', res)
                //         msg.id = serverMsgId
                //         msg.from = EaseChatClient.user
                //         const msgBody = createMessage.createMsgBody(msg)
                //         commit('UPDATE_MESSAGE_LIST', msgBody)
                //         // 提示会话列表更新
                //         dispatch('gatherConversation', msgBody.to)
                //         resolve('OK')
                //     })
                //     .catch((error) => {
                //         reject(error)
                //     })
            })
        },
        //添加通知类消息
        createInformMessage: ({ dispatch, commit }, params) => {
            /** 
               const params = {
                    from: '',
                    to: '',
                    chatType: '',
                    msg:''
                }
            */
            const msgBody = _.cloneDeep(params)
            msgBody.type = ALL_MESSAGE_TYPE.INFORM
            const key = setMessageKey(params)
            console.log('>>>>>>添加系统消息', params)
            commit('UPDATE_MESSAGE_LIST', msgBody)
            dispatch('gatherConversation', key)
        },
        //撤回消息
        recallMessage: async ({ dispatch, commit }, params) => {
            const { mid, to, chatType } = params
            return new Promise((resolve, reject) => {
                EaseChatClient.recallMessage({ mid, to, chatType })
                    .then(() => {
                        commit('CHANGE_MESSAGE_BODAY', {
                            type: 'recall',
                            key: to,
                            mid
                        })
                        dispatch('gatherConversation', to)
                        resolve('OK')
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}
export default Message
